import CO2SavingsChart from "./Components/CO2SavingsChart"
import CostSavings from "./Components/CostSavings"
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'


const SavingsChart = ({ id, currentWasteItem, dayvalue, datevalue, language, message1, selectedButton, totalwasteproduced, colorwaste, percentagechange }) => {

  return (
    <div style={{ minHeight: "50vh" }} className="relative">
      <div className="z-40 w-full px-4 h-25 py-4 pt-5">

        {/* THE BOX */}
          <div className="flex flex-row space-x-4 pt-5">
            <div className="mt-4 flex flex-col items-center justify-center">
              <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message1}` : `Total CO2 Savings ${selectedButton}`}</span>
              <div className="flex items-center justify-center gap-4">
                <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced}<span className="text-sm font-light">&nbsp;CO2e</span></span>{" "}
                <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste === "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
              </div>
            </div>
          </div>

      {/* CO2Savings Chart  */}
      <div className="grid lg:grid-cols-7 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 gap-4 pt-10">
        <div className="col-span-4">
          <CO2SavingsChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} datevalue={datevalue} />
        </div>
        <div className="col-span-3">
          <CostSavings id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} datevalue={datevalue}/>
        </div>
      </div>

      </div>
    </div>
  );
};

export default SavingsChart;
