import "../Waste-Dashboard/WasteDashboard.css"
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'
import SubclassDisplayChart from "./components/SubclassDisplayChart";


const SubclassDisplay = ({ id, currentWasteItem, dayvalue, language, message2, selectedButton, totalwasteproduced, colorwaste, percentagechange, TotalWasteWeight }) => {

  return (
    <div
      style={{ minHeight: "50vh" }}
      class="bg-white dark:bg-plaex-dark dark:text-white"
    >
      {/* Nav and Side Bar Import */}
      <div className="flex flex-row space-x-4 justify-center">
        <div className="mt-4 flex flex-col items-center justify-center">
          <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message2}` : `Subwaste Composition`}</span>
          <div className="flex items-center justify-center gap-4">
            <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced} &nbsp; <span className="font-light">Objects</span> &nbsp;<span className="font-light">|</span>&nbsp;{TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
            <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste === "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
          </div>
        </div>
      </div>

      <SubclassDisplayChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue}/>
    </div>
  );
};

export default SubclassDisplay;

