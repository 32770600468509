import PropTypes from 'prop-types';
import {useEffect, useMemo, useState} from 'react';
import axiosInstance from "../../../../api/axios";
import {AiOutlineArrowDown} from 'react-icons/ai'
import {AiOutlineArrowUp} from 'react-icons/ai'

const BubbleChart = (props) => {

    const {id, dayvalue, language, message2, totalwasteproduced, colorwaste, percentagechange, TotalWasteWeight} = props;
    const [bubbleData, setBubbleData] = useState([]);
    const [totalWasteObject, setTotalWasteObjects ] =  useState(0);

    useEffect(() => {

        axiosInstance.get('/waste_composition', {
            params: {
                id: id,
                day: dayvalue,
            }
        })
            .then(response => {
                console.log('/waste_composition', response)
                let paperNumObject =  Number(Object.values(response.data).at(0));
                let pmdNumObject =  Number(Object.values(response.data).at(1));
                let residualNumObject =  Number(Object.values(response.data).at(2));
                let organicNumObject =  Number(Object.values(response.data).at(3));

                setTotalWasteObjects(paperNumObject + pmdNumObject + residualNumObject + organicNumObject)

                const bubbleData = [
                    {id: 'paper', label: 'Paper', value: Object.values(response.data).at(0)},
                    {id: 'pmd', label: 'PMD', value: Object.values(response.data).at(1)},
                    {id: 'residual', label: 'Residual', value: Object.values(response.data).at(2)},
                    {id: 'organic', label: 'Organic', value: Object.values(response.data).at(3)}
                ];

                setBubbleData(bubbleData)
            })
            .catch(error => {
                console.error(error);
            });
    }, [dayvalue, id])


    const categories = [
        {id: 'paper', label: 'Paper', color: '#0000FF'},  // Blue
        {id: 'pmd', label: 'PMD', color: '#FFDB58'},      // Mustard
        {id: 'residual', label: 'Residual', color: '#FF0000'}, // Red
        {id: 'organic', label: 'Organic', color: '#008000'}   // Green
    ];

    const CircleRow = ({data}) => {
        const minSize = 100;  // Minimum size of the smallest circle
        const maxSize = 300;  // Maximum size of the largest circle

        const values = data.map((circle) => circle.value);
        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);

        const getSize = (value) => {
            const scaledValue = (value - minValue) / (maxValue - minValue);
            return scaledValue * (maxSize - minSize) + minSize;
        };

        const getColor = (id) => {
            const category = categories.find((category) => category.id === id);
            return category ? category.color : '#000000';
        };

        const sortedData = useMemo(() => {
            return [...data].sort((a, b) => b.value - a.value);
        }, [data]);

        return (
            <div style={{position: 'relative', padding: '20px'}}>
                {/* Chart Title */}
                <h1 style={{
                    textAlign: 'center',
                    color: 'darkgreen',
                    marginBottom: '20px',
                }}>
                <div className="flex flex-row space-x-4 justify-center">
                    <div className="mt-4 flex flex-col items-center justify-center">
                    <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message2}` : `Subwaste Composition`}</span>
                    <div className="flex items-center justify-center gap-4">
                        <span className="font-semibold flex items-center justify-center text-base">{totalWasteObject} &nbsp;
                            <span className="font-light">Objects</span> &nbsp;
                            <span className="font-light">|</span>&nbsp;
                            {TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
                        <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste === "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
                    </div>
                    </div>
                </div>
                </h1>

                {/* Legend positioned in a row in the top-right corner */}
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    borderRadius: '8px',
                    boxShadow: '0 0 5px rgba(0,0,0,0.2)'
                }}>
                    {categories.map((category) => (
                        <div key={category.id} style={{display: 'flex', alignItems: 'center', marginRight: '15px'}}>
                            <div style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: category.color,
                                marginRight: '10px'
                            }}></div>
                            <span>{category.label}</span>
                        </div>
                    ))}
                </div>

                {/* Circles arranged in a responsive row */}
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    width: '100%', // Ensure it stretches end to end
                    marginTop: '60px',
                }}>
                    {sortedData.map((circle) => {
                        const size = getSize(circle.value);
                        const color = getColor(circle.id);

                        return (
                            <div key={circle.id} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '10px',
                                flex: '1 1 100px', // Allows it to scale based on screen size
                                maxWidth: '25%', // Limit max size per item
                            }}>
                                <div
                                    style={{
                                        width: `${size}px`,
                                        height: `${size}px`,
                                        borderRadius: '50%',
                                        backgroundColor: color,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'white',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px'
                                    }}
                                >
                                    {circle.value}
                                </div>
                                <span style={{marginTop: '5px'}}>{circle.label}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    CircleRow.propTypes = {
        data: PropTypes.any.isRequired,
    };

    return (
        <div style={{height: 'auto', width: '100%'}}>
            <CircleRow data={bubbleData}/>
        </div>
    );
};

export default BubbleChart;