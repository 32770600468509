import axiosInstance from "api/axios";
import Navbar from "common/Components/NavBar/NavBar";
import Sidebar from "common/Components/SideBar/SideBar";
import { useEffect, useState } from "react";
import "./BinDashboard.css";
import BinChart from "./Components/BinChart";
import BinWasteTable from "./Components/BinWasteTable";
// import Hamburger from "common/Components/Hamburger/Hamburger";

export default function BinDashboard() {
  const [tableDate, setTableDate] = useState("");
  //const [theme, setTheme] = useState(localStorage.theme);

  const [darkM, setDarkM] = useState();
  const [selectedBin, setSelectedBin] = useState("0");
  const handleClick = (bin) => {
    setSelectedBin(bin);
  };

  useEffect(() => {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        setSelectedBin(response.data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div
      style={{ minHeight: "100vh" }}
      class="bg-white dark:bg-plaex-dark dark:text-white"
    >
      {/* Nav and Side Bar Import */}
      <Navbar />
      {/* 
      <Navbar
      
        handleClick={(e) => {
          setDarkM(e);
        }}
      />
      */}

      {/* <Sidebar onBinClick={handleClick} /> */}

      {/* <Hamburger onBinClick={handleClick}/> */}
      {/* Buttons for filtering waste by duration */}
      <h1>{}</h1>

      <div className="h-full ml-16 mt-24 mb-10 md:ml-64 p-4">
        {/* Waste Level Chart  */}
        <div className="">
          <BinChart darkM={darkM} bin={selectedBin} />
        </div>
        {/* Waste Items Table  */}
        <BinWasteTable bin={selectedBin} />
      </div>
    </div>
  );
}
