import axiosInstance from "api/axios";
import React, { useEffect, useState, useContext} from "react";
import { LanguageContext } from "context/LanguageContext";
import {
  AiFillDatabase,
  AiFillLeftSquare,
  AiFillRightSquare,
} from "react-icons/ai";
import "./Waste-Items.css";
import {FiFilter} from 'react-icons/fi'
import {BsCalendar2DateFill} from 'react-icons/bs'
import {GiWeightScale} from 'react-icons/gi'
import { MdArrowBack, MdArrowDownward, MdArrowUpward } from "react-icons/md";
import {AiOutlineClear} from 'react-icons/ai'

const WasteItems = (props) => {
  const { id, value, wastevalue } = props;
  const [wasteitem, setWasteItem] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [pagenumber, setPageNumber] = useState(null);
  const { language } = useContext(LanguageContext);
  const [sortDirectionWeight, setSortDirectionWeight] = useState("default");
  const [sortDirectionTime, setSortDirectionTime] = useState("default");
  const [sortBy, setSortBy] = useState('weight');

  const [filterclick, setFilterClick] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);


  const [counter, setCounter] = useState(1);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };


  const handleFilterChange = (value) => {
    switch (value) {
      case 'weightAsc':
        setSortBy('weight');
        setSortDirectionWeight(true);
        break;
      case 'weightDesc':
        setSortBy('weight');
        setSortDirectionWeight(false);
        break;
      case 'timeAsc':
        setSortBy('time');
        setSortDirectionTime(true);
        break;
      case 'timeDesc':
        setSortBy('time');
        setSortDirectionTime(false);
        break;
      case 'clear':
        setSortBy('');
        setSortDirectionTime("default");
        setSortDirectionWeight("default");
        break;
    }
  }
  

  const handlePrevClick = () => {
    if (pagination && pagination.previous) {
      if (counter === 1) {
        setCounter(counter);
      } else {
        setCounter(counter - 1);
      }
      //Replace http with https to avoid mixed content error
      // fetchWasteData(pagination.previous);
      fetchWasteData(pagination.previous.replace(/^http:\/\//i, "https://"));
    }
  };

  const handleNextClick = () => {
    if (pagination && pagination.next) {
      if (counter >= pagenumber) {
        setCounter(counter);
      } else {
        setCounter(counter + 1);
      }
      //Replace http with https to avoid mixed content error
      // fetchWasteData(pagination.next);
      fetchWasteData(pagination.next.replace(/^http:\/\//i, "https://"));
    }
  };

  const translateWasteToDutch = (text) => {
    const translations = {
      "organic": "Biologisch",
      "PMD": "PMD",
      "paper": "papier",
      "rest": "Resterend"
    };
    return translations[text] || text;
  }

  useEffect(() => {
    axiosInstance
      .get("/wasteitem", {
        params: {
          id: id,
          value: value,
          wastevalue: wastevalue,
          weight: sortDirectionWeight,
          time: sortDirectionTime,
        },
      })
      .then((response) => {
        console.log('/wasteitem', response)
        if (response.data.count !== 0) {
          setWasteItem(response.data.results);
          if (response.data.count < 5) {
            setPageNumber(1);
          } else {
            setPageNumber(Math.ceil(response.data.count / 5));
          }

          setPagination(response.data);
        } else {
          setWasteItem(response.data.results);
          setPagination(null);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [value, wastevalue, id, sortDirectionTime, sortDirectionWeight]);

  const fetchWasteData = (url) => {
    axiosInstance
      .get(url)
      .then((response) => {
        setWasteItem(response.data.results);
        setPagination(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <div>
      <section className="flex bg-white">
        <div className=" flex cursor-pointer right-0">
          <select className=" text-sm p-2 border dark:bg-plaex-dark rounded-md hover:text-plaex-green" onChange={(e) => handleFilterChange(e.target.value)}>
            <option value="" disabled selected className="flex justify-center items-center"> <FiFilter/>
            <h4>Sort</h4></option>
            <option value="weightAsc">Weight Ascending</option>
            <option value="weightDesc">Weight Descending</option>
            <option value="timeAsc">Date Ascending</option>
            <option value="timeDesc">Date Descending</option>
            <option value="clear">Clear Filter</option>
          </select>
          </div>

        </section>

      <div class="Table-Container">
        <table class="Table ">
          <thead>
            <tr>
              <th class="Cell-Property header">{language=== "Dutch" ? "Nee": "No."}</th>
              <th class="Cell-Property header">Type</th>
              <th class="Cell-Property header">{language=== "Dutch" ? "Gewicht": "Weight"}</th>
              <th class="Cell-Property header">{language=== "Dutch" ? "Naam": "Name"}</th>
              <th class="Cell-Property header">{language==="Dutch" ? "Datum" :"Date"}</th>
              <th class="Cell-Property header">{language === "Dutch" ? "Tijd": "Time"}</th>
            </tr>
          </thead>
          <tbody>
            {wasteitem &&
              wasteitem.map((row) => (
                <tr>
                  <td className="uppercase Cell-Property">{row.bin.unique_identifier}</td>
                  <td className="Cell-Property">{language === 'Dutch' ? translateWasteToDutch(row.waste_type) : row.waste_type === "rest" ? "Residual" : row.waste_type }</td>
                  <td className="Cell-Property">{Math.abs(row.waste_weight)}g</td>
                  <td className="Cell-Property">{row.sub_class_waste_type === "" ? "unidentified" : row.sub_class_waste_type}</td>
                  <td  className="Cell-Property">
                    {row.time_collected.split("T")[0]}
                  </td>
                  <td className="Cell-Property">
                    {row.time_collected.split("T")[1]}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {pagination && (
          <div className="flex flex-row gap-5 items-center justify-center mt-5 -ml-12">
            <button
              disabled={!pagination.previous}
              onClick={handlePrevClick}
              className="rounded-lg text-white p-2 text-[12px] cursor-pointer"
            >
              <AiFillLeftSquare className="text-gray-500 text-lg" />
            </button>
            <h4>
              {counter} of {pagenumber}
            </h4>
            <button
              disabled={!pagination.next}
              onClick={handleNextClick}
              className="rounded-lg text-white p-2 text-[12px] cursor-pointer"
            >
              <AiFillRightSquare className="text-green-500 text-lg" />
            </button>
          </div>
        )}

        {!pagination && (
          <div className="text-center flex items-center justify-center p-7">
            <div className="flex flex-col text-center items-center justify-center text-gray-400">
              <AiFillDatabase className="text-2xl" />
              <h1 className="text-[13px] -ml-2">{language === "Dutch" ?  "Geen Gegevens":"No Data"}</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WasteItems;
