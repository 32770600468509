import React, {useState, useContext} from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {BsFillChatLeftTextFill} from 'react-icons/bs'
import "./Feedback.css";
import axiosInstance from 'api/axios';
import { ToastContainer, toast } from 'react-toastify';
import {RiLoader4Fill} from 'react-icons/ri'
import 'react-toastify/dist/ReactToastify.css';
import { LanguageContext } from 'context/LanguageContext';

import { CSSTransition } from 'react-transition-group';

const Feedback = () => {
    const [showTextBox, setShowTextBox] = useState(false);
    const [isbuttonclicked, setButtonClicked] = useState(false);
    const [feedback, setFeedback] = useState('');
    const {language} = useContext(LanguageContext)
    const [name,setName] = useState('')

    const notifyFeedbackSent = () => toast("FeedBack Sent Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        progressClassName: 'my-progress-bar'
        });

        const notifyEmptyTextArea = () => {
          const message = language === "Dutch" ? "Tekstgebied mag niet leeg zijn!" : "Text Area Cannot be Empty!";
          toast(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

    const handleFeedbackChange = (event) => {
      setFeedback(event.target.value);
    };
    const handleFeedbackNameChange = (event) =>{
      setName(event.target.value);
    }
  
    const handleSendClick = async () => {
        if (!feedback || !name) {
            notifyEmptyTextArea()
            return;
            }
      setButtonClicked(true)
      try {
        const textdata = {
          feedback: feedback, 
          name: name
        };
        const response = await axiosInstance.post('feedback', textdata);
        notifyFeedbackSent()
        setFeedback('')
        setName('')
        setShowTextBox(false)
      } catch (error) {
        console.error(error);
      }
    };

    const handleIconClick = () => {
      setButtonClicked(false)
      setShowTextBox(!showTextBox);
    };
    return (
      <>
        <div
          className='bg-plaex-bar rounded-lg h-10 w-10 fixed bottom-3 right-5 flex justify-center items-center cursor-pointer'
          data-tooltip-id='feedback-tip'
          data-tooltip-content='Feedback'
          onClick={handleIconClick}
        >
          <BsFillChatLeftTextFill className='text-xl text-white hover:animate-bounce' />
        </div>
        <ReactTooltip id='feedback-tip' />
          <div className={showTextBox ? `fixed bottom-16 right-1 bg-white p-4 rounded-lg shadow-2xl SlideIn block` : `hidden SlideOut`}>
            <input type="text" required value={name} onChange={handleFeedbackNameChange} placeholder={language == "Dutch"? "Vul uw volledige naam In" : "Enter your Full Name"} className='w-full text-base mb-3 p-4 border whitespace-pre-line rounded-lg leading-relaxed focus:outline-none focus:border-plaex_eye_hide_unhide focus:border-2'/>
            <textarea
              className='w-full text-base h-64 p-4 border whitespace-pre-line rounded-lg leading-relaxed focus:outline-none focus:border-plaex_eye_hide_unhide focus:border-2'
              placeholder={language == "Dutch"? "Vul je Uw Feedback In..." : "Enter your Feedback Here..."}
              value={feedback}
              onChange={handleFeedbackChange}
              required
            />
            <button className='rounded-lg p-2 mt-1 text-white bg-plaex_eye_hide_unhide w-full hover:text-white hover:bg-black flex items-center justify-center' onClick={handleSendClick}>{isbuttonclicked? <RiLoader4Fill className='loading_icon font-semibold text-xl text-white '/>  : language === "Dutch"? "Versturen":"Send"}</button>
          </div>


        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
				/>
      </>
    );
}

export default Feedback
