import React, { useState, useEffect } from 'react';
import axiosInstance from "api/axios";
import {
  FaLeaf,
  FaRecycle,
  FaRegFileAlt,
  FaTrash,
  FaExclamationCircle,
} from 'react-icons/fa'; // Import icons
import './BinTable.css';

const useFetchData = (url) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(url);
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 30000); // Fetch every 60 seconds
        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [url]);

    return { data, loading, error };
};

const Table = ({ BinUniqueIdentifier }) => {
  const { data, loading, error } = useFetchData('/livechart');

  // Utility function to render alert icon if fill level exceeds threshold
  const renderAlertIcon = (fillLevel) => {
    const threshold = 80; // Set the caution threshold
    return fillLevel > threshold ? (
      <FaExclamationCircle className="alert-icon" style={{ color: 'red', marginLeft: '5px' }} />
    ) : null;
  };

  // Conditional rendering for loading, error, or data display
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  
  function Chart({bin}) {
    return (
      <div className="fill-levels tex-center" style={{ marginTop: '10px', textAlign: "center" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '3px', marginRight: '3px' }} className="fill-level-wrapper">
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="fill-level">
            <FaLeaf className="icon" />
            <span>Organic</span>
            <span className="bold-green">{bin.fillLevels.organic}%</span>
            <span style={{ marginLeft: '-5px' }}>{renderAlertIcon(bin.fillLevels.organic)}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }} className="progress-container">
            <div className="progress-bar">
              <div className="progress" style={{ width: `${bin.fillLevels.organic}%` }} />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '3px', marginRight: '3px'}} className="fill-level-wrapper">
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="fill-level">
          <FaRecycle className="icon" />
          <span>PMD</span>
          <span className="bold-green">{bin.fillLevels.pmd}%</span>
          <div style={{ marginLeft: '-5px' }}>{renderAlertIcon(bin.fillLevels.pmd)}</div>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }} className="progress-container">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${bin.fillLevels.pmd}%` }} />
          </div>
        </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '3px', marginRight: '3px' }} className="fill-level-wrapper">
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="fill-level">
          <FaRegFileAlt className="icon" />
          <span>Paper</span>
          <span  className="bold-green">{bin.fillLevels.paper}%</span>
          <span style={{ marginLeft: '-5px' }}>{renderAlertIcon(bin.fillLevels.paper)}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }} className="progress-container">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${bin.fillLevels.paper}%` }} />
          </div>
        </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '3px', marginRight: '3px' }} className="fill-level-wrapper">
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }} className="fill-level">
          <FaTrash className="icon" />
          <span>Residual</span>
          <span  className="bold-green">{bin.fillLevels.residual}%</span>
          <span style={{ marginLeft: '-5px' }}>{renderAlertIcon(bin.fillLevels.residual)}</span>
          </div>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '10px' }} className="progress-container">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${bin.fillLevels.residual}%` }} />
          </div>
        </div>
        </div>
      </div>
    );}

  const binJ1 = data.find(bin => bin.id === BinUniqueIdentifier);

  if (binJ1) {
    return <Chart bin={binJ1} />;
  } else {

  }
};

export default Table;