import axiosInstance from "api/axios";
import React, { useEffect, useState, useContext } from "react";
import "../Components/BinTable.css";
import {MdOutlineArrowForward } from "react-icons/md";
import { Link } from 'react-router-dom';
import {BsFillCircleFill} from 'react-icons/bs'
import { LanguageContext } from "context/LanguageContext";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Table from "./BinStatus";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BinTable = (props) => {
  const [bin, setBin] = useState([]);
  const { onBinClick, onoff } = props;
  const [bintype, setBinType] = useState(true);
  const [selectedButton, setSelectedButton] = useState('');
  const { language } = useContext(LanguageContext);
  const [chartData, setChartData] = useState({});


  useEffect(() => {
    axiosInstance
      .get("/get_bins")
      .then((response) => {
        const nonWeightScaleBins = response.data;
        setBin(nonWeightScaleBins)
        for (let i = 0; i < response.data.length; i++) {
          const binObj = response.data[i];
          if (binObj.is_garfill === true) {
            setBinType(false);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axiosInstance.get("status").then((response) => {
      const apiData = response.data;
      setChartData(prevChartData => {
        const newChartData = {...prevChartData};
        for (const binId in apiData) {
          const values = Object.values(apiData[binId]);
          const lastValue = Number(values[0]);
          newChartData[binId] = {
            labels: Object.keys(apiData[binId]).reverse(),
            datasets: [
              {
                label: lastValue === 1 ? 'Online' : 'Offline',
                data: values.reverse(),
                borderColor: lastValue === 1 ? 'green' : 'red',
                lineTension: 0.5,
                borderWidth: 2,
                fill: false,
              },
            ],
          };
        }
        return newChartData;
      });
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);
  
  const handleBinClick = (bin, bin_ident) => {
    props.onBinClick(bin);
    setSelectedButton(bin_ident);
  };
  
  return (
    <div className="sm:-ml-[50px] xs:-ml-[50px] lg:ml-0 md:ml-0 mt-10">
      <main
        id="binTable"
        class="dark:text-white text-slate-900 md:max-lg:flex sm:640px md:768px lg:1024px xl:1280px 2xl:1536px flex-col mb-16 mx-12">
        <section className="topSection" class="flex justify-between items-center pb-4">
          <h2 class="text-lg font-semibold ">{language === "Dutch"? "Bakken" :"Bins"}</h2>
          <Link to='/'>
            {bintype && <div class=" seeBinAll flex justify-between items-center gap-x-2.5 cursor-pointer text-sm ">
              <p>{language === "Dutch"? "Alles zien": "See All"}</p>
              <MdOutlineArrowForward />
            </div>}
          </Link>
        </section>

        <table className="table-fixed w-full text-center text-sm">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '65%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <tr className="text-base">
              <th>{language === "Dutch" ? "Nee." : "NO."}</th>
              <th>{language === "Dutch" ? "Plaats" : "Location"}</th>
              <th>{language === "Dutch" ? "Vulniveaus" : "Fill Levels"}</th>
              <th>{language === "Dutch" ? "Toestand": "Status"}</th>
            </tr>
          </thead>

          <tbody>
            {bin.map((bin) => (
              <tr
                key={bin.id}
                onClick={() => handleBinClick(bin, bin.unique_identifier)}
                className={`focus:bg-green-200 focus:bg-opacity-50 focus:outline-none text-sm ${selectedButton === bin.unique_identifier ? 'selected' : ''}`}
                tabindex="0"
              >
                <td style={{fontSize: '14px'}} className="uppercase truncate">{bin.unique_identifier}</td>
                <td style={{fontSize: '14px'}} className="truncate">{bin.bin_location}</td>
                <td>
                  <div style={{height: '80px', overflow: 'hidden', fontSize: '12px'}}>
                    <Table BinUniqueIdentifier={bin.id} />
                  </div>
                </td>
                <td className="flex justify-center mt-2">
                {Object.entries(onoff).map(([binId, status]) => {
                  if (binId === bin.unique_identifier) {
                    return (
                      <td key={binId} className="flex justify-center mt-2">
                        <span className="flex items-center space-x-1 status-cell">
                          <div style={{fontSize: '12px'}} className={status === false ? "bg-red-200 text-red-600 rounded-2xl p-2" : "bg-green-200 text-green-600 rounded-2xl p-2"}>
                            <span>{language === "Dutch" ? status === true ? "Op" : "Uit" : status === true ? "Online" : "Offline"}</span>
                          </div>
                        </span>
                      </td>
                    );
                  }
                })}
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
};


export default BinTable;