import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import WasteItems from "./Components/Waste-Items/Waste-Items";
import WasteLevelsChart from "./Components/Waste-Levels-Chart/Waste-Levels-Chart";


const Waste = ({ id, currentWasteItem, dayvalue, language, message, selectedButton, totalwasteproduced, colorwaste,
                 percentagechange, TotalWasteWeight, selectedValue, setSelectedValue }) => {
  return (
    <div
      style={{ minHeight: "100vh"}}
      class="bg-white dark:bg-plaex-dark dark:text-white"
    >
      <div className="z-40 w-full px-4 h-24 py-4 bg-white dark:bg-plaex-dark dark:text-white pt-16 mt-70">
      {/* Waste Level Chart  */}
      <div className="flex flex-row space-x-4">
      <div className="mt-4 flex flex-col items-center justify-center">
          <span className="text-green-700  text-[13px]">{language === "Dutch" ? `${message}` : `Total Waste compared to previous ${selectedButton}`}</span>
          <div className="flex items-center justify-center gap-4">
            <span className="font-semibold flex items-center justify-center text-base">{totalwasteproduced} &nbsp; <span className="font-light">Objects</span> &nbsp;<span className="font-light">|</span>&nbsp;{TotalWasteWeight} &nbsp; <span className="font-light">KG</span></span>{" "}
            <span className={`text-${colorwaste}-500 flex items-center justify-center text-[13px]`}>{percentagechange}% {colorwaste === "red"? <AiOutlineArrowUp/> : <AiOutlineArrowDown/>}</span>
          </div>
      </div>
      </div>

      <div className="Waste-Level-Chart-Container relative">
        <WasteLevelsChart id={id} wasteitem={currentWasteItem} dayvalue={dayvalue} />
      </div>
      
      {setSelectedValue(selectedButton)}

      {/* Waste Items Table  */}
      <div className="Waste-Items-Container">
        <div className="Waste-Items-Header">
        <h1 className="Waste-Items-h1 font-semibold text-xl">{language === "Dutch" ? "Afvalartikelen":  "Waste Items"}</h1>
          
          <div className="Recently-Added">
            {/* <div className="dropdown flex flex-col ">
              <div>
                <div className="ml-auto pr-6 ">
                  <label className= "font-semibold" for="waste">{language === "Dutch" ? "Recent Toegevoegd" : "Recently Added"}</label>
                  <br />
                  <select
                    onChange={(e) => {
                      setSelectedValue(e.target.value);
                    }}
                    className=" text-sm p-2 border dark:bg-plaex-dark rounded-md hover:text-plaex-green"
                  >
                    <option value="24hrs" selected>
                      {language === "Dutch" ? "24 Uur Geleden" : "24 Hour Ago"}
                    </option>
                    <option value="week">{language === "Dutch" ? "Een week" : "A Week"}</option>
                    <option value="month">{language === "Dutch" ?  "Een Maand Geleden ":"A Month Ago"}</option>
                    <option value="year">{language === "Dutch" ?  "Een Jaar Geleden ": "A Year Ago"}</option>
                  </select>
                </div>
              </div>
            </div> */}
          </div>

        </div>
        <WasteItems id={id} value={selectedValue} wastevalue={currentWasteItem} />

      </div>
      </div>
    </div>



  );
};


export default Waste;
